<template>
  <v-app>
    <ComplianceHeader />

    <v-main style="padding: 0">
      <v-container>
        <v-col cols="11" class="mx-auto pa-2 mb-10">
          <router-link
            :to="`${
              redirectToExternalPage() ? '/external/compliance' : '/compliance'
            }`"
            class="navigation-link"
          >
            <h3>Página Inicial</h3>
          </router-link>

          <strong class="text-black"> > </strong>

          <router-link
            :to="`${
              redirectToExternalPage()
                ? '/external/compliance/category/'
                : '/compliance/category/'
            }${subcategory.category.id || ''}`"
            class="navigation-link"
          >
            <h3>{{ categoryTitle }}</h3>
          </router-link>

          <strong class="text-black"> > </strong>

          <h3 class="subcategory-text">
            {{ subcategoryTitle }}
          </h3>
        </v-col>

        <v-col cols="11" class="mx-auto pa-2 mb-4">
          <h1 class="mb-8">
            Compliances - {{ subcategoryTitle }}
          </h1>

          <div v-if="paginatedArticles.length === 0" class="my-8 text-center">
            <h2>Nenhuma publicação encontrada.</h2>
          </div>

          <v-row v-else>
            <v-col
              v-for="article in paginatedArticles"
              :key="article.id"
              cols="12"
              md="4"
              sm="6"
            >
              <v-card
                height="500"
                elevation="10"
                class="mx-auto d-flex flex-column justify-space-between"
                color="white lighten-5"
                style="
                  border-radius: 15px;
                  transition: transform 0.3s;
                  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                "
              >
                <v-img
                  class="white--text align-end"
                  height="200px"
                  :src="article.featuredImage"
                  style="
                    border-top-left-radius: 15px;
                    border-top-right-radius: 15px;
                  "
                />
                <v-card-subtitle
                  class="text-uppercase pt-5 px-6 pb-0"
                  style="color: #616161"
                >
                  {{ article.createdAt | dateFormatted }}
                </v-card-subtitle>
                <v-card-text class="py-0 px-2 text-card">
                  <v-card-title class="text-truncate" style="font-weight: 600">
                    {{ article.headline }}
                  </v-card-title>
                  <v-card-subtitle class="text-truncate" style="color: #757575">
                    {{ article.caption }}
                  </v-card-subtitle>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    outlined
                    color="secondary"
                    :to="`${
                      redirectToExternalPage()
                        ? '/external/read-compliance/'
                        : '/read-compliance/'
                    }${article.slug}`"
                    style="border-radius: 20px; padding: 5px 20px; margin: 20px"
                  >
                    Ver mais
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-pagination
            v-model="currentPage"
            :length="pageCount"
            color="#173c93"
          />
        </v-col>
      </v-container>
    </v-main>

    <v-footer class="pa-0">
      <ComplianceFooter class="w-100" />
    </v-footer>
  </v-app>
</template>

<script>
import ComplianceFooter from "@/components/Compliance/ComplianceFooter.vue";
import ComplianceHeader from "@/components/Compliance/ComplianceHeader.vue";
import { FindSubcategoryById } from "@/services/compliance-subcategory-service";
export default {
  name: "ComplianceSubcategoryGrid",
  components: {
    ComplianceHeader: ComplianceHeader,
    ComplianceFooter: ComplianceFooter,
  },
  data() {
    return {
      subcategory: {
        id: undefined,
        name: "",
        image: "",
        category: {},
        compliances: [],
      },
      category: { id: undefined, title: "", featuredImage: "" },
      compliances: [],
      categoryTitle: "",
      subcategoryTitle: "",
      currentPage: 1,
      itemsPerPage: 6,
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.compliances.length / this.itemsPerPage);
    },
    paginatedArticles() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.compliances.slice(start, end);
    },
  },
  async created() {
    this.subcategory = await FindSubcategoryById(this.$route.params.id);
    if (this.subcategory) {
      this.compliances = this.subcategory.compliances.sort(
        (a, b) => a.id > b.id
      );
      this.category = this.subcategory.category;
      this.categoryTitle = this.category
        ? this.category.title
        : "Categoria não encontrada";
      this.subcategoryTitle = this.subcategory
        ? this.subcategory.name
        : "Subcategoria não encontrada";
    }
  },
  methods: {
    redirectToExternalPage() {
      return this.$route.name && this.$route.name.startsWith("external");
    },
    mountRouteArticle(articleSlug) {},
  },
};
</script>

<style>
.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.footer {
  width: 100%;
}

.navigation-link {
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
  color: #173c93 !important;
  display: inline-block;
}

.subcategory-text {
  font-weight: bold;
  display: inline-block;
}

.v-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}
</style>
