var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('ComplianceHeader'),_c('v-main',{staticStyle:{"padding":"0"}},[_c('v-container',[_c('v-col',{staticClass:"mx-auto pa-2 mb-10",attrs:{"cols":"11"}},[_c('router-link',{staticClass:"navigation-link",attrs:{"to":`${
            _vm.redirectToExternalPage() ? '/external/compliance' : '/compliance'
          }`}},[_c('h3',[_vm._v("Página Inicial")])]),_c('strong',{staticClass:"text-black"},[_vm._v(" > ")]),_c('router-link',{staticClass:"navigation-link",attrs:{"to":`${
            _vm.redirectToExternalPage()
              ? '/external/compliance/category/'
              : '/compliance/category/'
          }${_vm.subcategory.category.id || ''}`}},[_c('h3',[_vm._v(_vm._s(_vm.categoryTitle))])]),_c('strong',{staticClass:"text-black"},[_vm._v(" > ")]),_c('h3',{staticClass:"subcategory-text"},[_vm._v(" "+_vm._s(_vm.subcategoryTitle)+" ")])],1),_c('v-col',{staticClass:"mx-auto pa-2 mb-4",attrs:{"cols":"11"}},[_c('h1',{staticClass:"mb-8"},[_vm._v(" Compliances - "+_vm._s(_vm.subcategoryTitle)+" ")]),(_vm.paginatedArticles.length === 0)?_c('div',{staticClass:"my-8 text-center"},[_c('h2',[_vm._v("Nenhuma publicação encontrada.")])]):_c('v-row',_vm._l((_vm.paginatedArticles),function(article){return _c('v-col',{key:article.id,attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-card',{staticClass:"mx-auto d-flex flex-column justify-space-between",staticStyle:{"border-radius":"15px","transition":"transform 0.3s","box-shadow":"0 4px 10px rgba(0, 0, 0, 0.1)"},attrs:{"height":"500","elevation":"10","color":"white lighten-5"}},[_c('v-img',{staticClass:"white--text align-end",staticStyle:{"border-top-left-radius":"15px","border-top-right-radius":"15px"},attrs:{"height":"200px","src":article.featuredImage}}),_c('v-card-subtitle',{staticClass:"text-uppercase pt-5 px-6 pb-0",staticStyle:{"color":"#616161"}},[_vm._v(" "+_vm._s(_vm._f("dateFormatted")(article.createdAt))+" ")]),_c('v-card-text',{staticClass:"py-0 px-2 text-card"},[_c('v-card-title',{staticClass:"text-truncate",staticStyle:{"font-weight":"600"}},[_vm._v(" "+_vm._s(article.headline)+" ")]),_c('v-card-subtitle',{staticClass:"text-truncate",staticStyle:{"color":"#757575"}},[_vm._v(" "+_vm._s(article.caption)+" ")])],1),_c('v-card-actions',[_c('v-btn',{staticStyle:{"border-radius":"20px","padding":"5px 20px","margin":"20px"},attrs:{"outlined":"","color":"secondary","to":`${
                    _vm.redirectToExternalPage()
                      ? '/external/read-compliance/'
                      : '/read-compliance/'
                  }${article.slug}`}},[_vm._v(" Ver mais ")])],1)],1)],1)}),1)],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"color":"#173c93"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('v-footer',{staticClass:"pa-0"},[_c('ComplianceFooter',{staticClass:"w-100"})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }